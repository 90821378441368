// Fonts
$font-primary: 'Bai Jamjuree', sans-serif;
$font-secondary: 'Chakra Petch', sans-serif;

// Backgrounds
$deep-black: #000000;
$gold-copper: #FBC02D;
$off-black: #1E1E1E;
$dark-grey: #4B4B4B;
$light-grey: #909090;

// Text
$text-body: #FFFFFF;

