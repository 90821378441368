// Fonts
@import url('https://fonts.googleapis.com/css?family=Bai+Jamjuree');
@import url('https://fonts.googleapis.com/css?family=Chakra+Petch');
@import '~@fortawesome/fontawesome-pro/css/all.css';

@import 'variables';

$grid-columns: 12;
$grid-gutter-width: 20px;
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1400px
);
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1340px
);
@import '~bootstrap/scss/bootstrap';
html{
    overflow-y: scroll;
}
body{
    font-family: $font-primary;
    color: $light-grey;
    background: $deep-black;
    padding-right: 0 !important;
    &.mobileNav{
        .container{
            max-width: unset;
        }
    }
    &.modal-open{
        overflow: hidden;
    }
}
h1,.h1{
    transition: font-size 0.2s;
    font-family: $font-secondary;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 3.85rem;
    line-height: 3.7rem;
    color: #FFFFFF;
    span{
        color: $gold-copper;
    }
}
h2,.h2{
    transition: font-size 0.2s;
    font-family: $font-secondary;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 2.7rem;
    line-height: 2.5rem;
    color: #FFFFFF;
    span{
        color: $gold-copper;
    }
}
h3,.h3{
    transition: font-size 0.2s;
    font-family: $font-secondary;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 2rem;
    line-height: 2rem;
    color: #FFFFFF;
    span{
        color: $gold-copper;
    }
}
h4,.h4{
    position: relative;
    transition: font-size 0.2s;
    font-family: $font-secondary;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.7rem;
    line-height: 1.7rem;
    color: #FFFFFF;
    span{
        color: $gold-copper;
    }
    &:after{
        content: '';
        position: absolute;
        width: 2rem;
        height: .15rem;
        background: $gold-copper;
        bottom: -.75rem;
        left: 0;
    }
}
h5,.h5{
    position: relative;
    transition: font-size 0.2s;
    font-family: $font-secondary;
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 1.3rem;
    color: #FFFFFF;
    span{
        color: $gold-copper;
    }
}
h6,.h6{
    transition: font-size 0.2s;
    font-family: $font-secondary;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.1rem;
    line-height: 1.1rem;
    color: #FFFFFF;
    span{
        color: $gold-copper;
    }
}
p{
    margin-bottom: 1.25rem;
    &.highlight{
        color: #FFFFFF;
    }
    &:last-of-type{
        margin-bottom: unset;
    }
}
@include media-breakpoint-down(lg) {
    h1,.h1{
        font-size: 3.45rem;
        line-height: 3.3rem;
    }
    h2,.h2{
        font-size: 2.6rem;
        line-height: 2.4rem;
    }
    h3,.h3{
        font-size: 1.8rem;
        line-height: 1.8rem;
    }
    h5,.h5{
        font-size: 1.15rem;
        line-height: 1.15rem;
    }
}
@include media-breakpoint-down(md) {
    h1,.h1{
        font-size: 3rem;
        line-height: 2.9rem;
    }
    h2,.h2{
        font-size: 2.5rem;
        line-height: 2.3rem;
    }
    h3,.h3{
        font-size: 1.5rem;
        line-height: 1.5rem;
    }
    h4,.h4{
        font-size: 1.5rem;
        line-height: 1.5rem;
    }
    h5,.h5{
        font-size: 1rem;
        line-height: 1rem;
    }
}
@include media-breakpoint-down(sm) {
    h1,.h1{
        font-size: 2.25rem;
        line-height: 2.3rem;
    }
    h4,.h4{
        font-size: 1.25rem;
        line-height: 1.25rem;
    }
}
.btn{
    background: unset;
    &.btn-primary{
        position: relative;
        border: 2px solid #FFFFFF;
        font-weight: 600;
        color: #ffffff;
        font-size: .75rem;
        padding: 0.35rem 1rem;
        border-radius: unset;
        text-transform: uppercase;
        margin-right: 2rem;
        &:before{
            content: '';
            width: 2.09rem;
            height: 2.09rem;
            right: -2.09rem;
            position: absolute;
            top: -.14rem;
            border: 2px solid #fff;
        }
        &:after{
            position: absolute;
            transition: transform 0.2s;
            content: "\f061";
            font-family: 'Font Awesome 5 Pro';
            color: #FFF;
            right: -1.35rem;
            font-weight: 400;
        }
        &:hover{
            background: unset;
            // transform: translateY(-5px);
            &:after{
                transform: translateX(2px);
            }
        }
        &:active{
            background: unset!important;
            border: 2px solid #FFFFFF!important;
        }
    }
}

header{
    background: linear-gradient(180deg, rgb(9, 9, 9, .9) 40%, rgba(9,9,9,.01) 100%);
    z-index: $zindex-fixed;
    position: fixed;
    width: 100%;
    .wrapper{
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 1.5rem 0;
        .logo{
            img{
                height: 2rem;
            }
        }
        .socials{
            ul{
                display: flex;
                align-items: center;
                list-style: none;
                margin-bottom: 0;
                padding-left: 0;
                a{
                    transition: color 0.2s;
                    padding: .5rem;
                    color: #FFFFFF;
                    text-transform: uppercase;
                    font-size: 1.25rem;
                    &:hover{
                        text-decoration: none;
                        color: darken(#FFFFFF, 15%);
                    }
                }
            }
        }
        nav{
            ul{
                position: relative;
                display: flex;
                align-items: center;
                list-style: none;
                margin-bottom: 0;
                padding-left: 0;
                li.ext{
                    position: relative;
                    padding-right: .6rem;
                    &:after{
                        transition: margin-left 0.2s, color 0.2s;
                        font-family: 'Font Awesome 5 Pro';
                        content: '\f08e';
                        margin-left: -.25rem;
                        position: absolute;
                        padding-top: 3px;
                        font-weight: 300;
                        font-size: .6rem;
                    }
                }
                a{
                    text-decoration: unset;
                    position: relative;
                    transition: color 0.2s;
                    padding: .5rem;
                    font-weight: 600;
                    color: #FFFFFF;
                    text-transform: uppercase;
                    font-size: .9rem;
                    &:hover{
                        text-decoration: none;
                        color: darken(#FFFFFF, 15%);
                    }
                    &.active{
                        &:after{
                            content: '';
                            position: absolute;
                            width: 20px;
                            height: 2px;
                            background-color: $gold-copper;
                            left: 0.5rem;
                            bottom: 0;
                            z-index: $zindex-fixed;
                        }
                    }
                }
                li.dropdown{
                    ul{
                        display: none;
                        position: absolute;
                        padding-top: 2.5rem;
                        left: 0;
                        flex-direction: column;
                        gap: .5rem;
                        align-items: flex-start;
                        font-weight: 400;
                        li{
                            background: rgba(0,0,0,.6);
                            a{
                                text-transform: unset;
                                font-size: .9rem;
                                font-weight: 400;
                                display: flex;
                                align-items: center;
                                gap: .3rem;
                                padding: .25rem .6rem;
                                width: 6rem;
                                i{
                                    font-size: .75rem;
                                }
                                &.login{
                                    display: flex;
                                    justify-content: center;
                                    background: linear-gradient(90deg, rgba(232,136,112,1) 0%, rgba(175,32,255,1) 100%);
                                    @include media-breakpoint-down(lg) {
                                        background: unset;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .burger{
            display: none;
            font-size: 1.5rem;
            z-index: 11;
            touch-action: manipulation;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }
    }
    @include media-breakpoint-down(lg){
        .socials{
            display: none;
        }
    }
    @include media-breakpoint-down(lg) {
        //padding: 1rem 1.5rem;
        nav{
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100vh;
            flex-direction: column;
            justify-content: center;
            background: rgba(0, 0, 0, 0.5);
            backdrop-filter: blur(20px);
            z-index: 10;
            ul{
                display: flex;
                flex-direction: column;
                li{
                    padding: .75rem .5rem;
                    a{
                        padding: .5rem !important;
                        font-size: 1.2rem;
                        &:before{
                            bottom: .3rem;
                        }
                    }
                }
            }
            &.active{
                display: flex;
                .dropdown{
                    ul{
                        display: block !important;
                        position: relative !important;
                        padding-top: unset !important;
                        li{
                            background: unset !important;
                            padding: unset !important;
                            padding-left: .75rem !important;
                        }
                    }
                }
            }
        }
        .logo{
            img{
                height: 1.5rem !important;
            }
        }
        .burger{
            display: block !important;
            cursor: pointer;
        }
    }
}
footer{
    .contact-us{
        background: $off-black;
        position: relative;
        padding-top: 3rem;
        // padding-bottom: 7rem;
        .contact{
            margin-right: 2rem;
            a{
                text-decoration: unset;
                transition: color 0.2s;
                &:hover{
                    text-decoration: none;
                    color: $gold-copper;
                }
            }
        }
        .stamp{
            position: relative;
            img{
                position: absolute;
                left: 0;
                width: 100%;
                z-index: $zindex-sticky;
            }
        }
    }
    &.map{
        .contact-us{
            background: unset;
        }
    }
    &.careers{
        .contact-us{
            background: #0F0F0F;
        }
    }
    #map{
        height: 30rem;
        width: 100%;
    }
    .main{
        background: $deep-black;
        padding-bottom: 4rem;
        padding-top: 4rem;
        .logo{
            margin-bottom: 3rem;
            a{
                text-decoration: unset;
                img{
                    height: 2rem;
                }
            }
        }
        ul{
            list-style: none;
            margin-top: 2rem;
            margin-bottom: 2rem;
            padding: 0;
            a{
                text-decoration: unset;
                transition: color 0.2s;
                color: $light-grey;
                text-transform: uppercase;
                li{
                    margin-bottom: .35rem;
                }
                &:hover{
                    text-decoration: unset;
                    color: lighten($light-grey,15%);
                }
            }
        }
    }
    @include media-breakpoint-down(lg) {
        .contact-us{
            padding-bottom: 5rem;
            h2{
                margin-bottom: 2rem;
            }
            .contacts{
                flex-direction: column;
                .contact{
                    margin-bottom: 1rem;
                }
            }
        }
    }
    @include media-breakpoint-down(md) {
        .contact-us{
            padding-bottom: 3rem;
            &:before{
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: url('/images/elements/vindex-stamp.svg') no-repeat;
                background-position: right;
                background-size: 125%;
                opacity: 0.03;
            }
        }
    }
}
.hero{
    padding-top: 15rem;
    @include media-breakpoint-down(lg) {
        padding-top: 10.5rem;
    }
    @include media-breakpoint-down(md) {
        padding-top: 8rem;
    }
    @include media-breakpoint-down(sm) {
        padding-top: 6rem;
    }
}
.page-head{
    padding-bottom: 10rem;
    .title{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        div{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 2rem;
            height: 2rem;
            background: $off-black;
            i{
                color: #FFFFFF;
                font-size: .8rem;
            }
        }
    }
    img{
        height: 2.25rem;
        margin-bottom: 5rem;
    }
    @include media-breakpoint-down(md) {
        .title{
            flex-direction: unset;
            margin-bottom: 2rem;
        }
    }
}

// Index
#powering-the-future{
    padding-bottom: 10rem;
    background: url('/images/backgrounds/powering-the-future.jpg') no-repeat;
    background-size: cover;
    background-position: center;
    h1{
        margin-bottom: 5rem;
    }
    .arrow{
        div{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 2rem;
            height: 2rem;
            background: $off-black;
            i{
                color: #FFFFFF;
                font-size: .8rem;
            }
        }
    }
    .about{
        a{
            text-decoration: unset;
            float: right;
        }
    }
    .logos{
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
        img{
            margin-right: 2rem;
        }
        img{
            &:nth-child(1){
                height: 5rem;
            }
            &:nth-child(2),&:nth-child(3){
                height: 2rem;
            }
        }
    }
    @include media-breakpoint-down(lg) {
        padding-bottom: 8rem;
        .logos img{
            margin-right: 1.75rem;
            &:nth-child(1){
                height: 4rem;
            }
            &:nth-child(2),&:nth-child(3){
                height: 1.75rem;
            }
        }
    }
    @include media-breakpoint-down(md) {
        h1{
            margin-bottom: 2rem;
        }
        padding-bottom: 5rem;
        .about{
            a{
                margin-top: 3rem;
                float: unset;
            }
        }
        .logos {
            padding-top: 2rem;
            border-top: 1px solid #4B4B4B;
            img{
                margin-right: 1.5rem;
                &:nth-child(1){
                    height: 2rem;
                }
                &:nth-child(2),&:nth-child(3){
                    height: 1.25rem;
                }
            }
        }
    }
    @include media-breakpoint-down(sm) {
        padding-bottom: 3rem;
    }
}
#build-engagement{
    background: #2C2C2C;
    padding: 6rem 0;
    .items-wrapper{
        position: relative;
        .items{
            position: relative;
            scrollbar-width: none;
            display: flex;
            overflow-x: scroll;
            flex-wrap: nowrap;
            padding: 2rem 0;
            padding-right: 3rem;
            .item{
                padding: .5rem;
                img.background{
                    width: 100%;
                    margin-bottom: 1.5rem;
                }
                .header{
                    display: flex;
                    gap: 2rem;
                    margin-bottom: .75rem;
                    img{
                        max-height: 3rem;
                        max-width: 8rem;
                    }
                    div{
                        font-size: .85rem;
                    }
                    h3{
                        text-transform: unset;
                        font-weight: 300;
                    }
                }
            }

            scrollbar-width: 10px;
            scrollbar-color: #3C3C3C #2C2C2C;
              &::-webkit-scrollbar {
                width: 10px;
              }

              &::-webkit-scrollbar-track {
                background: #2C2C2C;
              }

              &::-webkit-scrollbar-thumb {
                background-color: #3C3C3C;
                border-radius: 5px;
                border: 3px solid #2C2C2C;
              }
            @include media-breakpoint-down(sm) {
                flex-wrap: wrap;
                .item{
                    margin-bottom: 3.5rem;
                }
            }
        }
        &::after{
            content: '';
            position: absolute;
            background: linear-gradient(90deg, rgba($color: #2C2C2C, $alpha: 0) 0%, rgba($color: #2C2C2C, $alpha: 1) 100%);
            width: 6rem;
            height: 100%;
            right: 0;
            top: 0;
        }
        @include media-breakpoint-down(md) {
            .items{
                padding-right: unset;
            }
            &::after{
                display: none;
            }
        }
    }
    @include media-breakpoint-down(md) {
        padding-top: 3rem;
        padding-bottom: unset;
        background: linear-gradient(90deg, rgba(15,15,15,1) 0%, rgba(48,48,48,1) 100%);
        .items-wrapper .items{
            overflow-x: hidden;
            .item{
                margin-bottom: 1rem;
            }
        }
    }
}
#vindex-solutions{
    padding: 7.5rem 0;
    background: url('/images/backgrounds/vindex-solutions.jpg') no-repeat;
    background-size: cover;
    background-position: center;
    h2{
        margin-bottom: 6rem;
    }
    .logo{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        a{
            text-decoration: unset;
            margin-top: 2rem;
            img{
                height: 3rem;
                margin-right: 1rem;
                filter: brightness(100);
            }
            i{
                transition: color 0.2s;
                color: #FFFFFF;
            }
            &:hover{
                i{
                    color: $gold-copper;
                }
            }
        }
    }
    @include media-breakpoint-down(md) {
        background-position: left;
        background-position-x: -20rem;
        .logo{
            justify-content: flex-start;
            a{
                margin-top: 4rem;
                img{
                    height: 2rem;
                }
            }
        }
    }
}
#vindex-experiences{
    padding: 7.5rem 0;
    background: url('/images/backgrounds/vindex-experiences.jpg') no-repeat;
    background-size: cover;
    background-position: center;
    h2{
        margin-bottom: 11rem;
    }
    .logo{
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        a{
            text-decoration: unset;
            img{
                height: 3rem;
                margin-right: 1rem;
                filter: brightness(100);
            }
            i{
                transition: color 0.2s;
                color: #FFFFFF;
            }
            &:hover{
                i{
                    color: $gold-copper;
                }
            }
        }
    }
    @include media-breakpoint-down(md) {
        background-position: left;
        background-position-x: -20rem;
        .logo{
            justify-content: flex-start;
            a{
                margin-top: 4rem;
                img{
                    height: 2rem;
                }
            }
        }
    }
}

// About
#about-vindex{
    background: url('/images/backgrounds/about-vindex.jpg') no-repeat;
    background-size: cover;
    background-position: center;
}
#employee-cards{
    margin-top: 2rem;
    .employee{
        background: $off-black;
        padding: 3.25rem;
        margin-bottom: 2rem;
        h3{
            margin-bottom: .75rem;
        }
        h5{
            margin-bottom: 3rem;
            &:after{
                content: '';
                position: absolute;
                width: 2rem;
                height: .15rem;
                background: $gold-copper;
                bottom: -1rem;
                left: 0;
            }
        }
        .highlights{
            margin-top: 2rem;
            h6{
                cursor: pointer;
                position: relative;
                padding-left: 1.5rem;
                &:before{
                    font-family: 'Font Awesome 5 Pro';
                    font-weight: 200;
                    font-size: 1rem;
                    color: $gold-copper;
                    content: '\f067';
                    position: absolute;
                    left: 0;
                    width: .75rem;
                    height: 2px;

                }
            }
            ul{
                list-style: none;
                padding-left: 2.25rem;
                margin-bottom: 0;
                margin-top: 1.5rem;
                display: none;
                li{
                    position: relative;
                    margin-bottom: 1rem;
                    &:before{
                        background: #FFFFFF;
                        content: '';
                        position: absolute;
                        left: -2.25rem;
                        top: 1rem;
                        width: .75rem;
                        height: 1px;
                    }
                    &:after{
                        background: #FFFFFF;
                        content: '';
                        position: absolute;
                        left: -2.25rem;
                        top: .65rem;
                        width: 1px;
                        height: .35rem;
                    }
                    &:last-of-type{
                        margin-bottom: unset;
                    }
                }
            }
            &.open{
                h6{
                    &:before{
                        content: '\f068';
                    }
                }
            }
        }
    }
    @include media-breakpoint-down(lg) {
        .employee{
            padding: 2rem;
        }
    }
    @include media-breakpoint-down(sm) {
        .employee{
            margin-bottom: 1rem;
        }
    }
}
#about-careers{
    padding: 10rem 0;
    h2{
        margin-bottom: 2rem;
    }
    a{
        text-decoration: unset;
        margin-top: 3rem;
    }
}

// Careers
#careers{
    background: #1E1E1E;
    padding-bottom: 5rem;
    @include media-breakpoint-down(lg) {
        background: #0F0F0F;
        padding-bottom: 2rem;
    }
}
#careers-companies{
    background: #1E1E1E;
    padding-bottom: 7.5rem;
    .company{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        img{
            height: 2.5rem;
            margin-bottom: 2rem;
        }
        p{
            margin-bottom: 1.5rem;
            min-height: 5rem;
        }
        a{
            text-decoration: unset;
            color: #fff;
            text-transform: uppercase;
            text-decoration: unset;
            font-family: $font-primary;
            font-size: .85rem;
            font-weight: 900;
            i{
                transition: all 0.2s;
                margin-left: .4rem;
                font-weight: 300;
            }
            &:hover{
                i{
                    margin-left: .55rem;
                }
            }
        }
    }
    @include media-breakpoint-down(lg) {
        background: #0F0F0F;
        padding-bottom: 2rem;
        .company{
            padding: 1.5rem;
            background: #1E1E1E;
            margin-bottom: 1rem;
            img{
                height: 2rem;
                margin-bottom: 1rem;
            }
            p{
                min-height: unset;
                margin-bottom: 1rem;
            }
            a{
                // margin-bottom: 3.5rem;
            }
        }
    }
    @include media-breakpoint-down(sm) {
        padding-bottom: 1rem;
        .company{
            img{
                height: 1.5rem;
            }
        }
    }
}

// Who We Are
#who-we-are{
    background: #0F0F0F;
    padding-bottom: 1.5rem;
    h3{
        line-height: 1.35;
        margin-bottom: 2rem;
    }
    .wrapper{
        background: rgb(15,15,15);
        background: linear-gradient(180deg, rgba(15,15,15,1) 0%, rgba(40,33,16,1) 100%);
        .blocks{
            padding: 1rem 1rem 0 1rem;
            .block-wrapper{
                margin-bottom: 1rem;
                .block{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 1rem;
                    height: 100%;
                    padding-bottom: 2rem;
                    img{
                        width: 70%;
                        margin: 1rem 0;
                        max-width: 7rem;
                    }
                    p{
                        font-size: .85rem;
                        color: #ABABAB;
                        text-align: center;
                    }
                    &.esports-engine{
                        background-color: transparentize(#0F0F0F, .25);
                        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23B42A34' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='2' stroke-linecap='square'/%3e%3c/svg%3e");
                    }
                    &.belong{
                        background-color: transparentize(#0F0F0F, .25);
                        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%239003FF' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='2' stroke-linecap='square'/%3e%3c/svg%3e");
                    }
                }
            }
        }
        .bar{
            width: 100%;
            height: .1rem;
            background: rgb(251,192,45);
            background: linear-gradient(270deg, rgba(251,192,45,1) 0%, rgba(216,91,0,1) 25%, rgba(251,192,45,1) 50%, rgba(255,239,199,1) 75%, rgba(251,192,45,1) 100%);
        }
    }
    .img{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        img{
            width: 80%;
            height: unset;
            margin-bottom: unset;
        }
    }
    .title-bar{
        height: 5rem;
        display: flex;
        align-items: center;
        i{
            color: #fff;
            margin-right: 1rem;
        }
    }
}
#what-we-do{
    padding-top: 7.5rem;
    padding-bottom: 1.5rem;
    background: url('https://cdn.vindex.gg/backgrounds/background-stars.jpg') no-repeat;
    background-size: cover;
    background-position: center center;
    h3{
        padding-bottom: 3rem;
    }
    .block{
        position: relative;
        background: #1E1E1E;
        .header{
            position: relative;
            height: 5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            text-transform: uppercase;
            font-size: 1.1rem;
            font-weight: 900;
        }
        .content{
            display: flex;
            flex-direction: column;
            gap: .5rem;
            padding: .5rem;
            .background-block{
                position: relative;
                height: 6.5rem;
                div{
                    width: 7.5rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    background-color: transparentize(#0F0F0F, .25);
                    color: #fff;
                    text-transform: uppercase;
                }
                &.esports{
                    background: url('https://cdn.vindex.gg/backgrounds/background-esports.jpg') no-repeat;
                    background-size: cover;
                    background-position: top center;
                }
                &.streaming{
                    background: url('https://cdn.vindex.gg/backgrounds/background-streaming.jpg') no-repeat;
                    background-size: cover;
                    background-position: top center;
                }
                &.events{
                    background: url('https://cdn.vindex.gg/backgrounds/background-events.jpg') no-repeat;
                    background-size: cover;
                    background-position: top center;
                }
                @include media-breakpoint-up(xl) {
                    &:after{
                        font-family: 'Font Awesome 5 Pro';
                        content: '\f054';
                        width: 1rem;
                        height: 1rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: #4B4B4B;
                        position: absolute;
                        right: -1.65rem;
                        top: calc(50% - .5rem);
                    }
                }
            }
            .text-block{
                display: flex;
                position: relative;
                justify-content: center;
                align-items: center;
                height: 6.5rem;
                background: #0F0F0F;
                div{
                    width: 8.5rem;
                    display: flex;
                    flex-shrink: 0;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    background-color: transparentize(#0F0F0F, .25);
                    color: #fff;
                    text-transform: uppercase;
                }
                p{
                    font-size: .85rem;
                    padding: 0 1rem;
                    flex-grow: 1;
                }
                &.analytics div{
                    background: url('https://cdn.vindex.gg/backgrounds/background-analytics.jpg') no-repeat;
                    background-size: cover;
                    background-position: top center;
                }
                &.production div{
                    background: url('https://cdn.vindex.gg/backgrounds/background-production.jpg') no-repeat;
                    background-size: cover;
                    background-position: top center;
                }
                &.engagement div{
                    background: url('https://cdn.vindex.gg/backgrounds/background-engagement.jpg') no-repeat;
                    background-size: cover;
                    background-position: top center;
                }
                @include media-breakpoint-up(xl) {
                    &:after{
                        font-family: 'Font Awesome 5 Pro';
                        content: '\f054';
                        width: 1rem;
                        height: 1rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: #4B4B4B;
                        position: absolute;
                        right: -1.65rem;
                        top: calc(50% - .5rem);
                    }
                }
            }
            .gold-text{
                display: flex;
                justify-content: center;
                align-items: center;
                height: 6.5rem;
                background-color: transparentize(#0F0F0F, .25);
                div{
                    text-align: center;
                    padding: 1.5rem;
                    color: #fff;
                    font-weight: 900;
                    span{
                        color: $gold-copper;
                    }
                }
            }
        }
        &.content-formats{
            .header{
                background: #484848;
                @include media-breakpoint-up(xl) {
                    &:after{
                        font-family: 'Font Awesome 5 Pro';
                        content: '\f054';
                        width: 1rem;
                        height: 1rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: #fff;
                        position: absolute;
                        right: -1.15rem;
                        font-weight: 400;
                        top: calc(50% - .5rem);
                    }
                }
            }
        }
        &.services-provided{
            .header{
                background: #818181;
                @include media-breakpoint-up(xl) {
                    &:after{
                        font-family: 'Font Awesome 5 Pro';
                        content: '\f054';
                        width: 1rem;
                        height: 1rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: #fff;
                        position: absolute;
                        right: -1.15rem;
                        font-weight: 400;
                        top: calc(50% - .5rem);
                    }
                }
            }
        }
        &.customer-value{
            background: rgb(30,30,30);
            background: linear-gradient(180deg, rgba(30,30,30,1) 0%, rgba(53,46,30,1) 100%);
            .header{
                background: #fff;
                color: #000;
                font-weight: 900;
            }
        }
    }
    .title-bar{
        margin-top: 4rem;
        height: 5rem;
        display: flex;
        align-items: center;
        i{
            color: #fff;
            margin-right: 1rem;
        }
    }
}
#how-we-do-it{
    padding-top: 7.5rem;
    padding-bottom: 1.5rem;
    padding-bottom: 5rem;
    background: #0F0F0F;
    h3{
        padding-bottom: 3rem;
    }
    .block{
        background: rgb(15,15,15);
        background: linear-gradient(180deg, rgba(15,15,15,1) 0%, rgba(40,33,16,1) 100%);
        .property{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 2.5rem 0;
            img{
                width: 8rem;
                margin-bottom: .5rem;
            }
            a{
                text-decoration: unset;
                font-size: .85rem;
                margin-bottom: 1rem;
                text-decoration: none;
                color: $light-grey;
                transition: color 0.2s;
                &:hover{
                    color: #fff;
                }
            }
            .divider{
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                .top{
                    height: 1.25rem;
                    width: 1px;
                    background: #484643;
                }
                .bottom{
                    width: 100%;
                    height: 1px;
                    background: #484643;
                }
            }
            ul{
                margin-top: 1.5rem;
                list-style: none;
                padding-left: 0;
                margin-bottom: 0;
                li{
                    font-size: .95rem;
                    text-align: center;
                    color: #fff;
                    text-transform: uppercase;
                    height: 2.5rem;
                }
            }
        }
    }
    .bar{
        width: 100%;
        height: .1rem;
        background: rgb(251,192,45);
        background: linear-gradient(270deg, rgba(251,192,45,1) 0%, rgba(216,91,0,1) 25%, rgba(251,192,45,1) 50%, rgba(255,239,199,1) 75%, rgba(251,192,45,1) 100%);
    }
}

// Team
#team{
    background: url('/images/backgrounds/about-vindex.jpg') no-repeat;
    background-size: cover;
    background-position: center;
    h3{
        line-height: 1.35;
        margin-bottom: 2rem;
    }
}
#team-grid{
    background: rgb(15,15,15);
    background: linear-gradient(90deg, rgba(15,15,15,1) 0%, rgba(75,75,75,1) 100%);
    position: relative;
    padding-top: 10rem;
    padding-bottom: 2rem;
    .row{
        position: relative;
        margin-top: -17.5rem;
    }
    .team-member{
        margin-bottom: 3rem;
        .img{
            background-size: cover;
            background-position: center -20px;
            width: 100%;
            height: 250px;
            margin-bottom: 2rem;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            .social{
                text-decoration: none;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 2.5rem;
                height: 2.5rem;
                background: rgba($color: #000000, $alpha: 0.35);
                margin-bottom: unset;
                i{
                    color: rgb(219, 219, 219);
                    transition: color 0.2s;
                }
                &:hover i{
                    color: #ffffff;
                }
            }
            @include media-breakpoint-down(lg) {
                background-position: center -10px;
            }
        }
        .title{
            font-size: .85rem;
            color: #ABABAB;
            text-transform: uppercase;
            margin-bottom: .5rem;
            min-height: 2.6rem;
        }
        .name{
            font-family: $font-secondary;
            font-size: 1.85rem;
            line-height: 1.85rem;
            color: #fff;
            min-height: 4rem;
            margin-bottom: .25rem;
        }
        a{
            text-decoration: none;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 2.5rem;
            height: 2.5rem;
            background: rgba($color: #000000, $alpha: 0.35);
            margin-bottom: 1rem;
            i{
                color: rgb(219, 219, 219);
                transition: color 0.2s;
            }
            &:hover i{
                color: #ffffff;
            }
        }
        p{
            line-height: 1.2rem;
        }
    }
}
// Solutions
#solutions-head{
    background: url('/images/backgrounds/esports-engine.jpg') no-repeat;
    background-size: cover;
    background-position: center;
    img{
        filter: brightness(100);
    }
}
#solutions-esports-engine{
    background: url('/images/backgrounds/esports-engine-body.jpg') no-repeat;
    background-size: cover;
    background-position: center;

    padding: 5rem 0;
    p{
        margin-top: 10rem;
        padding-left: 0;
        padding-right: 0;
    }
    .link{
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }
    @include media-breakpoint-down(md) {
        background-position: left;
        p{
            margin-top: unset;
        }
        .link{
            padding-top: 3rem;
            display: unset;
            justify-content: unset;
            align-items: unset;
        }
    }
}
#solutions-letsplay{
    background: url('/images/backgrounds/letsplay.jpg') no-repeat;
    background-size: cover;
    background-position: center;

    padding: 5rem 0;
    p{
        margin-top: 10rem;
        padding-left: 0;
        padding-right: 0;
    }
    .link{
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }
    @include media-breakpoint-down(md) {
        background-position: left;
        p{
            margin-top: unset;
        }
        .link{
            padding-top: 3rem;
            display: unset;
            justify-content: unset;
            align-items: unset;
        }
    }
}

// Experiences
#experiences-head{
    background: url('/images/backgrounds/belong-gaming-arenas.jpg') no-repeat;
    background-size: cover;
    background-position: center;
}
#experiences-belong{
    background: url('/images/backgrounds/belong-gaming-arenas-body.jpg') no-repeat;
    background-size: cover;
    background-position: center;

    padding: 5rem 0;
    p{
        margin-top: 10rem;
        padding-left: 0;
        padding-right: 0;
    }
    .link{
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }
    @include media-breakpoint-down(md) {
        background-position: left;
        p{
            margin-top: unset;
        }
        .link{
            padding-top: 3rem;
            display: unset;
            justify-content: unset;
            align-items: unset;
        }
    }
}

// News
#news-head{
    background: url('/images/backgrounds/about-vindex.jpg') no-repeat;
    background-size: cover;
    background-position: center;
    padding-bottom: 3rem;
    ul{
        list-style: none;
        display: flex;
        padding-left: 0;
        margin-bottom: 0;
        li{
            transition: color 0.2s;
            font-size: .95rem;
            font-weight: 600;
            text-transform: uppercase;
            color: $dark-grey;
            padding: .5rem .75rem;
            &.active{
                color: $gold-copper!important;
            }
            &:hover{
                cursor: pointer;
                color: #725B25;
            }
        }
    }
}
#articles{
    padding-top: 2rem;
    .article{
        background-color: $off-black;
        width: 100%;
        margin-bottom: 2rem;
        padding: 1.25rem;
        > .header{
            margin-bottom: 2rem;
            .published{
                display: flex;
                justify-content: space-between;
                .date{
                    color: $light-grey;
                    font-size: .8rem;
                    text-transform: uppercase;
                }
                .publisher{
                    font-size: .8rem;
                    a{
                        text-decoration: unset;
                        transition: color 0.2s;
                        color: $gold-copper;
                        font-size: .8rem;
                        text-transform: uppercase;
                        &:hover{
                            text-decoration: none;
                            color: #FFFFFF;
                        }
                    }
                }
            }
            .details{
                display: flex;
                align-items: center;
                margin: 1rem 0 1.5rem 0;
                img{
                    width: 4.5rem;
                    height: 4.5rem;
                    border-radius: 10rem;
                    flex-shrink: 0;
                }
                h3{
                    margin-left: 1rem;
                    position: relative;
                    &:before{
                        content: '';
                        position: absolute;
                        bottom: -15px;
                        width: 25px;
                        height: 2px;
                        background-color: $gold-copper;
                    }
                }
            }
        }
        > .body{
            p{
                color: $light-grey;
            }
        }
        > .footer{
            display: flex;
            justify-content: flex-end;
            a{
                text-decoration: unset;
                color: $light-grey;
                font-size: .9rem;
                text-transform: uppercase;
                text-decoration: none;
                padding-right: 2rem;
                &:after{
                    transition: margin-left 0.2s, color 0.2s;
                    font-family: 'Font Awesome 5 Pro';
                    content: '\f061';
                    margin-left: 1rem;
                    position: absolute;
                    padding-top: 1px;
                }
                &:hover{
                    &:after{
                        margin-left: calc(1rem + 5px);
                        color: $gold-copper;
                    }
                }
            }
        }
    }
    @include media-breakpoint-down(sm) {
        .article{
            margin-bottom: 1rem;
        }
    }
}

.coin {
    position: relative;
    overflow: hidden;
    width: 200px;
    height: 200px;
    margin-bottom: -2rem;
}

.coin img {
    position: absolute;
    top: 0;
    left: 0;
}


// Timeline
.tl-item{
    padding: 2rem 0;
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(15,15,15,1) 100%);
    .detail{
        h2{
            text-transform: uppercase;
        }
    }
    .tl-images{
        display: flex;
        .reference{
            flex-grow: 1;
            flex-basis: 0;
        }
        .arrow{
            transition: width 0.2s, height 0.2s,;
            flex-shrink: 0;
            width: 3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            i{
                transition: transform 0.2s;
            }
        }
        .video{
            flex-grow: 1;
            flex-basis: 0;
        }
    }
    @include media-breakpoint-down(lg){
        .detail{
            margin-bottom: 1rem;
        }
    }
    @include media-breakpoint-down(md){
        .tl-images{
            flex-direction: column;
            .arrow{
                height: 3rem;
                width: unset;
                i{
                    transform: rotate(90deg);
                }
            }
        }
    }
}

// Partners

#vindex-partners{
    padding-top: 10rem;
    padding-bottom: 5rem;
    background: url('/images/backgrounds/about-vindex.jpg') no-repeat;
    background-size: cover;
    background-position: center;
    .logos{
        margin-top: 3rem;
        display: flex;
        flex-wrap: wrap;
        img{
            height: 3rem;
            margin-right: 4rem;
            margin-bottom: 2rem;
        }
    }
    @include media-breakpoint-down(md) {
        h2{
            margin-bottom: 2rem;
        }
    }
}
#partners-quote{
    padding: 4rem 0;
    background: #141414;
    .wrapper{
        justify-content: space-between;
        align-items: flex-end;
        h4{
            line-height: 2.2rem;
        }
        .name{
            display: flex;
            justify-content: flex-end;
            p{
                color: $gold-copper;
            }
            @include media-breakpoint-down(lg) {
                margin-top: 2rem;
                justify-content: flex-start;
            }
        }
    }
}
#featured-in{
    padding: 5rem 0;
    .heading{
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        p{
            color: $gold-copper;
            text-transform: uppercase;
            margin-bottom: 3rem;
        }
    }
    .logos{
        display: flex;
        align-items: center;
        justify-content: space-between;
        img{
            width: 20%;
        }
    }
}
.anchor {
    display: block;
    position: relative;
    top: -65px;
    visibility: hidden;
}
#privacy{
    ul:not(.menu){
        li{
            margin-bottom: .75rem;
        }
    }
    strong {
        color: white;
    }
    h4{
        margin: 2rem 0;
    }
    a{
        color: $gold-copper;
        text-decoration: unset;
        transition: color 0.2s;
        &:hover{
            color: #fff;
        }
    }
    table {
        border: 1px solid #909090;
        width: 100%;
        margin-bottom: 1rem;
        th {
            padding: 1rem;
        }
        tbody{
            tr{
                border: 1px solid #909090;
                td{
                    ul {
                        margin-top: .75rem;
                        li {
                            margin-bottom: unset!important;
                        }
                    }
                }
            }
        }
    }
}

#contact-section{
    height: 12.5rem;
    @include media-breakpoint-down(lg){
        height: 8.5rem;
    }
    @include media-breakpoint-down(md){
        height: 3rem;
    }
}

#esl {
    padding-top: 12rem;
    padding-bottom: 6.5rem;
    h1 {
        font-size: 3rem;
        margin-bottom: 2.5rem;
    }
    .esl-subtitle {
        color: $gold-copper;
        padding-left: 1rem;
    }
    img {
        width: 100%;
        margin-top: 1rem;
        margin-bottom: 2rem;
    }
    .esl-content {
        p, ul, li {
            color: white
        }
        .esl-content_quote {
            font-size: 1.5rem;
            font-style: italic;
        }
        .esl-content_copper {
            text-transform: uppercase;
            color: $gold-copper;
            font-weight: 500;
        }
        .esl-content_link {
            color: $gold-copper;
            text-decoration: underline;
        }
    }
    @include media-breakpoint-down(lg) {
        padding-top: 8rem;
        h1 {
            font-size: 2.5rem;
            line-height: 1.2;
        }
        .esl-content {
            .esl-content_quote {
                font-size: 1.25rem;
            }
        }
    }
    @include media-breakpoint-down(md) {
        padding-top: 7rem;
        h1 {
            font-size: 1.8rem;
            margin-bottom: 1.5rem;
        }
    }
}
